
import {  PageBase } from '@/core/models/shared';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioDespesaChave extends PageBase { 
    service = new EmpreendimentoService();
    item: Model[] = [];
    overlay: boolean = false; 
    filter:any = {}

    mounted() {
       this.filter = this.$route.query;
        this.overlay = true;  

            this.service.ListarIptu(this.filter.empreendimentoId, this.filter.grupoId, this.filter.unidadeId, this.filter.clienteId, this.filter.empresaId).then(
                res => {
                    this.item = res.data;
                }, 
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                } 
            ).finally(() =>{this.overlay = false});
        } 
}

class Model {
   public clienteNomeCPF: string = "";
   public clienteLogradouro: string = "";
   public clienteCidade:string = "";
   public clienteUf:string = "";
   public clienteCep: string = "";
   public clienteBairro: string = "";
   public empreendimentoNome: string = "";
   public grupoNome:string = "";
   public unidadeNome:string = "";
   public unidadeMatricula:string = "";
   public unidadeAreaTotal:number = 0;
}
